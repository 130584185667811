import { doc, cursor, updatePositions, scrl, siteurl, registeredResizeFunctions, registeredLoops, getWindowSize} from '../../js/utils/events.js';

export default class TransparentVideoOverlay {
    init(root) {
      this.root = root;
      this.video = this.root
      this.updateVideoSource = this.updateVideoSource.bind(this);

      console.log('this.root')
      console.log(this.root)

      registeredResizeFunctions.push({ //add this.loop to global requestAnimationFrame
          root: this.root,
          component: this,
          resize: this.resize
      })
  
      // window.addEventListener('resize', this.updateVideoSource);
      this.updateVideoSource(); // Call once to set the initial source
    }

    resize() {

      console.log('resize transparent video overlay')
      this.component.updateVideoSource();
        
    }
  
    updateVideoSource() {
      const screenWidth = window.innerWidth;
      const isLandscape = screenWidth > 768; // Adjust this breakpoint as needed
  
      if ((isLandscape && this.currentOrientation === 'landscape') || (!isLandscape && this.currentOrientation === 'portrait')) {
        return; // Exit if the current orientation is already set correctly
      }
  
      this.video.innerHTML = ''; // Clear any existing sources
  
      if (isLandscape) {
        this.currentOrientation = 'landscape';
        this.video.classList.add('landscape');
        this.video.classList.remove('portrait');
        this.addSource('./assets/videos/loyaltyring_particles-hevc.mov', 'video/mp4; codecs="hvc1"');
        this.addSource('./assets/videos/loyaltyring_particles-transparent.webm', 'video/webm');
      } else {
        this.currentOrientation = 'portrait';
        this.video.classList.add('portrait');
        this.video.classList.remove('landscape');
        this.addSource('./assets/videos/loyaltyring_particles_3x4-hevc.mov', 'video/mp4; codecs="hvc1"');
        this.addSource('./assets/videos/loyaltyring_particles_3x4-transparent.webm', 'video/webm');
      }
  
      this.video.load();
    }
  
    addSource(src, type) {
      const source = document.createElement('source');
      source.src = src;
      source.type = type;
      this.video.appendChild(source);

      setTimeout(() => {
        this.video.play();
      }, 5000)
    }
  }
  
  // Example usage:
  // const overlay = new TransparentVideoOverlay();
  // overlay.init(document.querySelector('.video-container')); // Assuming the video is inside a container with the class 'video-container'
  