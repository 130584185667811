import { doc, cursor, touchDevice, globalLoops } from './../../js/utils/events.js';

export default class CustomCursor {
  constructor() {
    this.lastUpdateTime = 0;
    this.updateInterval = 16; // ~60 FPS (1000ms / 60)
  }

  init() {
    console.log(touchDevice); // is a Bool ... only create the cursor if false

    if (!touchDevice) {
      this.test = 'this is how to access the component from the loop';
      this.render();
      this.addEventListeners();

      globalLoops.push({ // adding this.loop to global requestAnimationFrame
        component: this,
        loop: this.loop.bind(this)
      });
    }
  }

  render() {
    this.cursor = document.createElement('div');
    this.cursor.id = 'custom-cursor';
    document.body.appendChild(this.cursor);
  }

  addEventListeners() {
    const selectors = ['.cursor-trigger', 'a', 'button', '.button', '.case', '.featuredteaser', '.video-embed-lightbox', '.burger'];

    // Event listener for footer hover
    const footer = document.querySelector('footer');
    if (footer) {
      footer.addEventListener('mouseenter', () => {
        this.cursor.classList.add('on-green');
      });

      footer.addEventListener('mouseleave', () => {
        this.cursor.classList.remove('on-green');
      });
    }

    const swiperContainer = document.querySelector('.swiper-container');
    if (swiperContainer) {
      swiperContainer.addEventListener('mousemove', this.updateCursorPosition.bind(this));
      swiperContainer.addEventListener('touchmove', this.updateCursorPosition.bind(this));
    }
  }

  loop(time) {
    if (!cursor.event) {
      return; // Exit the loop early if no event is available
    }

    // Throttle updates
    if (time - this.lastUpdateTime < this.updateInterval) {
      requestAnimationFrame(() => this.loop(time));
      return;
    }

    this.lastUpdateTime = time;

    // Update cursor position based on global cursor variable
    this.cursor.style.left = `${cursor.xRaw}px`;
    this.cursor.style.top = `${cursor.yRaw}px`;

    // Check the element directly under the cursor
    const target = document.elementFromPoint(cursor.xRaw, cursor.yRaw);

    // Update cursor appearance based on element under the cursor
    const selectors = ['.cursor-trigger', 'a', 'button', '.button', '.case', '.featuredteaser', '.video-embed-lightbox', '.burger']; //'.switchdarklight-toggle'
    const isHoveringRelevant = selectors.some(selector => target && target.matches(selector));

    if (isHoveringRelevant) {
      if (target.classList.contains('cursor-text')) {
        this.cursor.classList.add('text-hovered');
        this.cursor.textContent = target.getAttribute('data-text');
      } else {
        this.cursor.classList.add('hovered');
        this.cursor.textContent = ''; // Clear text for regular links
      }
    } else {
      this.cursor.classList.remove('hovered', 'text-hovered');
      this.cursor.textContent = ''; // Clear text when not hovering over relevant elements
    }

    // For debugging
    // console.log(this.test);
    // console.log(cursor);

    // Continue the animation loop
    requestAnimationFrame(() => this.loop(time));
  }
}
