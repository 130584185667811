import { spanFerkel } from '../../js/utils/math.js';
import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, touchDevice, siteurl, lenisScrollTo, jumpTo, addGlobalEventListener} from '../../js/utils/events.js';
import { gsap } from "gsap";

export default class WorkFilter {
  init(root) {
    this.root = root;
    this.workGridEl = this.root.querySelector('.work-grid')
    this.filterButtons = this.root.querySelectorAll('.work-filter button')
    this.filterTagsWrapper = this.root.querySelector('.work-filter-tags .options')
    this.namesEl = this.root.querySelector('.work-filter-names .options')
    this.resultEl = this.root.querySelector('.work-filter-resultnote')
    this.resetServices = this.root.querySelector('.work-filter-services .reset')
    this.resetNames = this.root.querySelector('.work-filter-names .reset')
    this.namesInfo = this.root.querySelector('.work-filter-names .info')
   
    // this.resetNames.addEventListener('click', e => {
    //   this.namesEl.querySelectorAll('button').forEach(btn => {
    //     if(!btn.classList.contains('active')) {
    //       btn.classList.add('active')
    //       this.filter()
    //     }
    //   })
    // })
    // this.resetServices.addEventListener('click', e => {
    //   this.showFeatured()
    // })
    
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
    
    this.workJSON;
    fetch(`${siteurl}/culture.json`)
    .then(results => results.json())
    .then( data => {
      this.workJSON = data
      this.buildFilter()
      this.addFilterEvents()
      this.filter() //initally sow all 
      if(this.urlParams.get('position') || this.urlParams.get('singlename')){
        // this.filterByURLParams()
        // console.log('FILTER BY URL PARAMS')
      } else {
        // this.showFeatured();
        // console.log('show featured or all projects on initial load')
      }
    });
  }

  getAllTagsInUse() {
    const portfolio = this.workJSON[0].portfolio;
    const allTags = [];

    portfolio.forEach(project => {
        const tagsArray = project.tags.split(', ');
        tagsArray.forEach(tag => {
            if (!allTags.includes(tag)) {
                allTags.push(tag);
            }
        });
    });
    return allTags;
  }

  buildFilter() {
    let tags = this.getAllTagsInUse()
    // console.log(tags)

    tags.forEach(tag => {
      let btn = document.createElement("button");
      btn.classList.add('button')
      // btn.classList.add('secondary')
      btn.classList.add('button-tag')
      btn.setAttribute('data-filter', tag);
      // btn.setAttribute('data-names', filteredPosition[0].members);
      btn.innerHTML = tag;
      btn.addEventListener('click', (e, btn) => {
        // this.showNameOptions(filteredPosition[0].members)
        // console.log(e.target)
        this.applyFilter(e.target)
        // console.log('apply filter:', tag)
      })
      this.filterTagsWrapper.appendChild(btn);
    })

    this.filterTagsWrapper.classList.remove('hidden')
  }


  addFilterEvents() {
    // console.log('filterButtons')
    // console.log(this.filterButtons)
    this.filterButtons.forEach(btn => {
      // btn.setAttribute('data-filter', tag);
      // btn.setAttribute('data-names', filteredPosition[0].members);
      // btn.innerHTML = tag;
      btn.addEventListener('click', (e, btn) => {
        // this.showNameOptions(filteredPosition[0].members)
        // console.log(e.target)
        this.applyFilter(e.target)
        // console.log('apply filter:', tag)
      })
    })
  }

  applyFilter(btn) {
    if(btn.classList.contains('active')) {
      btn.classList.remove('active')
      // this.namesEl.innerHTML = ""
    } else {
      this.filterTagsWrapper.querySelectorAll('button').forEach(el => {
        el.classList.remove('active');
      })
      btn.classList.add('active')
    }
    this.filter()
  }
  // showNameOptions(members) {
  //   this.namesEl.innerHTML = ""
  //   this.namesInfo.classList.remove('hidden');
  //   members.forEach(member => {
  //     let btn = document.createElement("button");
  //     btn.setAttribute('data-filter', member);
  //     btn.innerHTML = member;
  //     // btn.classList.add('active')
  //     btn.addEventListener('click', (e) => {
  //       if(btn.classList.contains('active')) {
  //         btn.classList.remove('active')
  //       } else {
  //         this.namesEl.querySelectorAll('button').forEach(el => {
  //           el.classList.remove('active');
  //         })
  //         btn.classList.add('active')
  //       }
  //       this.filter()
  //     })
  //     this.namesEl.appendChild(btn);
  //   })
  // }
  filter() {
    const portfolio = this.workJSON[0].portfolio;


    //look up filter inputs
    this.activeTags = [];
    if(!this.filterTagsWrapper.querySelector('.active')) {
      // console.log('no active tag')
      this.renderThumbnails(portfolio)
      this.filterButtons[0].classList.add('active')
      return;
    } else {
      
      const tags = this.root.querySelectorAll('.active');
      
      if(tags[0].dataset.filter == "all") {
        // console.log('button: all')
        this.renderThumbnails(portfolio)
        return;
      } else {
        this.root.querySelectorAll('.active').forEach(tag => {
            this.activeTags.push(tag.dataset.filter)
        })
        const filteredPortfolio = portfolio.filter(project => {
          // Split tags string into an array
          const tagsArray = project.tags.split(', ');
          // Check if any of the active tags match the project tags
          return this.activeTags.some(tag => tagsArray.includes(tag));
        });
        this.renderThumbnails(filteredPortfolio)
      }
    }

    // const filterInput  = {
    //   position: service,
    //   names: activeNames
    // }

    //filter the original array with the filter inputs
    // const filteredArray = this.workJSON[0].portfolio.filter(function(object) {
    //   return object.members.filter(member => member.position === filterInput.position && filterInput.names.includes(member.name)).length;
    // });



    // if(filterInput.names.length == 0) {
    //   if(filterInput.position == undefined) {
    //     this.showFeatured()
    //   } else {
    //     this.resultEl.innerHTML = `0 works. Please select an artist`
    //   }
    // } else {
    //   this.resultEl.innerHTML = `${filteredArray.length} works by ${filterInput.names.join(', ')}`
    // }
  }

  showFeatured() {
    this.renderThumbnails(this.workJSON[0].featured)
    this.resultEl.innerHTML = `Featured Work`
    this.namesEl.innerHTML = ""
    this.filterTagsWrapper.querySelectorAll('button').forEach(btn => {
      btn.classList.remove('active')
    })
    this.namesInfo.classList.add('hidden');
  }

  filterByURLParams() {
    let singlename = this.urlParams.get('singlename')
    if(singlename){
      this.filterSingleName(singlename)
    } else {
      let position = this.urlParams.get('position')
      const currentServiceEl = this.filterTagsWrapper.querySelector(`[data-filter='${position}']`);
      currentServiceEl.classList.add('active');
      let names = this.urlParams.get('names').split(',')
      this.showNameOptions(currentServiceEl.getAttribute('data-names').split(','))
      if(names.length < 2) { //if more than one it will show all
        this.namesEl.querySelector(`[data-filter='${names[0]}']`).classList.add('active');
      }
      this.filter()
      window.setTimeout(() => {
        jumpTo(this.root, -16, 'smooth')
      }, 200);
    }
  }

  renderThumbnails(filteredArray) {
    let workThumbnails = "";
    this.restOfCurrentThumbnails = "";

    let params = "";
    if(this.activeTags.length > 0) {
      params = `?tags=${this.activeTags}`;
    }


    // let limitItems = false;
    // let itemMaxAmount = 16; //if the amount is higher than this value
    // let itemStopAmount = 12; //we will only show this amount -> making sure there is more than one row behind the "see all" button
    // let itemLimit = 10000;
    // let workThumbnailsAll = "";

    // if(filteredArray.length > itemMaxAmount) {
    //   limitItems = true;
    //   this.loadAllWrapper.classList.add('visible')
    //   this.loadAllHint.innerHTML = `You have viewed ${itemStopAmount} out of ${filteredArray.length}.`;
    // } else {
    //   this.loadAllWrapper.classList.remove('visible')
    // }

    filteredArray.forEach((thumb, index) => {
      let url = thumb.url;
      let title = thumb.title;
      let date = thumb.date;
      let mainimage = thumb.thumbnail;
      
      const template = `
      <a class="teaser work-grid-item" href="${url}${params}" style="opacity: 0;">
        <span class="teaser-date">${date}</span>
        <div class="teaser-visual">
            <img class="teaser-mainimage" src="${mainimage}" alt="${title}">
        </div>
        <h3>${title}</h3>
      </a>
    `;

      workThumbnails += template;
    })

    const oldItems = this.workGridEl.querySelectorAll('.work-grid-item')

    gsap.to(oldItems, {
      y: '10%',
      opacity: 0,
      duration: 0.2,
      stagger: {
        each: 0.05,
        grid: "auto"
      },
      onComplete: () => {
        this.workGridEl.innerHTML = workThumbnails;
        //add events to new items
        const newItems = this.workGridEl.querySelectorAll('.work-grid-item')
        // newItems.forEach(el => {
          // if(touchDevice === false && doc.w > 720) {
          //   el.addEventListener('mousemove', this.mouseMove);
          //   el.addEventListener('mouseleave', this.mouseLeave);
          // }
        // })
        window.setTimeout(() => {
          gsap.fromTo(this.workGridEl.querySelectorAll('.work-grid-item'), {
            y: '10%',
            opacity: 0,
          }, {
            y: '0',
            opacity: 1,
            duration: 0.2,
            stagger: {
              each: 0.1,
              grid: "auto"
            },
            onComplete: function () {
              gsap.set(this.targets(), { clearProps: "transform" });
            }
          })
        }, 100);
      }
    })
  }
}

