import { loopback, map, clamp } from './../../js/utils/math.js';
import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, lenisScrollTo, stopLenis, startLenis, touchDevice} from './../../js/utils/events.js';

export default class FeaturedTeaser {
    init(root) {
        this.root = root
        this.grid = this.root.querySelector('.featuredteaser-grid')
        this.wrapper = this.root.querySelector('.featuredteaser-wrapper')

        //get grid items
        this.allGridItems = Array.from(this.grid.children);
        this.firstThreeItems = this.allGridItems.slice(0, 3);
        this.lastThreeItems = this.allGridItems.slice(-3);

        // this.text = this.root.querySelector('.hero-text')
        // this.text = this.root.querySelector('.hero-text')
        // this.textBottom = this.root.querySelector('.text-bottom')
        this.render()
        // console.log('this.root')
        // console.log(this.root)
        registeredLoops.push({ //add this.loop to global requestAnimationFrame
            root: this.root,
            component: this,
            position: getPosition(this.root), //trigger element
            loop: this.loop
        })

        // this.root.style.marginBottom = "50svh";
    }

    render() {
        console.log(this.root)
    }

    loop(time) {
        const inView = inViewport(this.position)
        // console.log(inView)
        const progress = clamp(inView.progress, 0, 1);
        // console.log(progress)
        // this.component.grid.style.opacity = map(progress, 0.5, 1, 1, 0);
        this.component.grid.style.transform = `translate3d(-50%, -50%, 0) scale(${map(progress, 0, 0.5, 0.3, 1)})`;
        // this.component.wrapper.style.transform = `translate3d(0, ${map(progress, 0, 1, -100, 200)}%, 0)`;
        this.component.wrapper.style.clipPath = `polygon(0 ${map(progress, 0, 0.3333, 100, 0)}%, 100% ${map(progress, 0, 0.3333, 100, 0)}%, 100% ${map(progress, 0.6666, 1, 100, 0)}%, 0 ${map(progress, 0.6666, 1, 100, 0)}%)`;
        
        this.component.firstThreeItems.forEach(el => {
            el.style.transform = `translate3d(${map(progress, 0.25, 0.5, 0, -20)}%, 0, 0)`;
        })
        
        this.component.lastThreeItems.forEach(el => {
            el.style.transform = `translate3d(${map(progress, 0, 0.5, -20, 20)}%, 0, 0)`;
        })

        if(inView.loopback === 1) {
            // this.root.classList.add('fade-in')
            this.component.wrapper.style.pointerEvents = 'auto';
            this.component.wrapper.style.display = 'block';
            // this.component.text.style.pointerEvents = 'auto';
            // this.component.textBottom.style.pointerEvents = 'auto';
        } else {
            // this.root.classList.remove('fade-in')
            this.component.wrapper.style.pointerEvents = 'none';
            this.component.wrapper.style.display = 'none';
            // this.component.text.style.pointerEvents = 'none';
            // this.component.textBottom.style.pointerEvents = 'none';
        }
    }
}
