export default class Cases {
    init(root) {
        this.root = root;
        this.root.addEventListener('click', (event) => this.handleCaseClick(event));
        console.log('this.root')
        console.log(this.root)
        const loadAllButton = this.root.querySelector('#load-all-cases');
        if (loadAllButton) {
            loadAllButton.addEventListener('click', () => this.loadAllCases());
        }
    }

    handleCaseClick(event) {
        console.log(event.target)
        console.log(event.target.classList.contains('featuredteaser') || event.target.classList.contains('video-embed-lightbox'))
        let caseElement;
        if(event.target.classList.contains('featuredteaser') || event.target.classList.contains('video-embed-lightbox')) {
            caseElement = this.root;
        } else {
            caseElement = event.target.closest('.case');
        }
        if (caseElement) {
            const urltype = caseElement.getAttribute('data-urltype');
            const url = caseElement.getAttribute('data-iframe-url');
            if (urltype === "vimeo") {
                this.fetchVimeoEmbed(url);
            }
        }
    }

    fetchVimeoEmbed(url) {
        // Add parameters to the URL
        const params = new URLSearchParams({
            url: url,
            dnt: 'true',          // Do Not Track
            title: 'false',       // Hide title
            byline: 'false',      // Hide byline
            portrait: 'false',    // Hide portrait
            controls: '1',        // Hide all controls
            autoplay: '1'         // Autoplay the video
        });
    
        // Construct the oEmbed URL with parameters
        const oembedUrl = `https://vimeo.com/api/oembed.json?${params.toString()}`;
    
        fetch(oembedUrl)
            .then(response => response.json())
            .then(data => {
                this.openLightbox(data.html);
                // Add a delay to ensure the lightbox is rendered before trying to play
                setTimeout(() => {
                    // Find the iframe within the lightbox
                    const iframe = document.querySelector('.lightbox iframe');
                    if (iframe) {
                        const player = new Vimeo.Player(iframe);
                        player.play();
                    }
                }, 500);
            })
            .catch(error => {
                console.error('Error fetching Vimeo embed code:', error);
            });
    }
    
    
    

    openLightbox(embedHtml) {
        // Create the lightbox overlay
        const lightbox = document.createElement('div');
        lightbox.classList.add('lightbox');

        // Add the embed HTML
        const modalPlayerTop = document.createElement('div');
        modalPlayerTop.innerHTML = embedHtml;
        modalPlayerTop.classList.add('lightbox-player');

        // Create the close button
        const closeButton = document.createElement('button');
        // closeButton.textContent = '×';
        closeButton.innerHTML = `
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.91602 25.9717L25.916 1.97189" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
                <path d="M1.91602 1.97168L25.916 25.9715" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
            </svg>
            `;
        closeButton.classList.add('lightbox-close');

        // Close button event listener
        closeButton.addEventListener('click', () => {
            document.body.removeChild(lightbox);
            document.body.style.overflow = "auto";
        });

        // Append elements to the lightbox
        lightbox.appendChild(modalPlayerTop);
        lightbox.appendChild(closeButton);

        document.body.style.overflow = "hidden";

        // Append the lightbox to the body
        document.body.appendChild(lightbox);
    }

    loadAllCases() {
        const hiddenCases = this.root.querySelectorAll('.case--hidden');
        hiddenCases.forEach(caseElement => caseElement.classList.remove('case--hidden'));

        const loadAllButton = this.root.querySelector('#load-all-cases');
        if (loadAllButton) {
            loadAllButton.remove();
        }
    }

    render() {
        console.log(this.root);
    }
}