export default class SwitchDarkLight {
  init(root) {
      this.root = root
      this.body = document.querySelector('body');
      this.toggle = this.root.querySelector('.switchdarklight-toggle');

      if(document.querySelector('body').classList.contains('dark')) {
        this.switchToDark()
      }

      this.render()
      this.hintAnimation()
  }

  render() {
    this.toggle.addEventListener('click', e => {
      if(this.root.classList.contains('switchdarklight--light')) {
        this.switchToDark()
      } else {
        this.switchToLight()
      }
    })
  }

  switchToDark() {
    this.root.classList.remove('switchdarklight--light');
    this.root.classList.add('switchdarklight--dark');
    this.body.classList.remove('light');
    this.body.classList.add('dark');
  }

  switchToLight() {
    this.root.classList.remove('switchdarklight--dark');
    this.root.classList.add('switchdarklight--light');
    this.body.classList.remove('dark');
    this.body.classList.add('light');
  }

  hintAnimation() {
    if(this.body.classList.contains('light')) {
      this.switchToDark()
      setTimeout(() => {
        this.switchToLight()
      }, 1000);
    } else {
      this.switchToLight()
      setTimeout(() => {
        this.switchToDark()
      }, 1000);
    }
  }
}
